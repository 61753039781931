import * as React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import joinClasses from '/src/functions/joinClasses'
import './styles.scss'

const Alert = ({ variant, size, compact, content, className }) => {
    const classes = [
        'alert',
        ...(variant ? [`alert-${variant}`] : ['alert-warning']),
        'c-alert',
        ...(size ? [`c-alert--${size}`] : []),
        ...(compact ? ['c-alert--compact'] : []),
        ...(className ? [className] : [])
    ]

    const concatenatedClasses = joinClasses(classes)

    return (
        <div className={concatenatedClasses} role="alert">
            {parse(content)}
        </div>
    )
}

Alert.propTypes = {
    /**
     * Specify a different style variant
     */
    variant: PropTypes.oneOf(['info', 'danger', 'success']),
    /**
     * Specify a different style variant
     */
    size: PropTypes.oneOf(['sm', 'lg']),
    /**
     * Remove the margins
     */
    compact: PropTypes.bool,
    /**
     * Alert content (mixed HTML)
     */
    content: PropTypes.string.isRequired,
    /**
     * Optional CSS classes
     */
    className: PropTypes.string
}

Alert.defaultProps = {
    content: '<p>Alert content goes here</p>'
}

export default Alert
